import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import useGetUserAccessPermission from "../../core/hooks/useGetUserAccessPermission";
import useGetUserAccessRoles from "../../core/hooks/useGetUserAccessRoles";
import useGetUserAccessOperations from "../../core/hooks/useGetUserAccessOperations";
import useGetUserAccessRolePermissions from "./../../core/hooks/useGetUserAccessRolePermissions";
// import SelectInput from "./../../core/form-components/SelectInput";
import useAddUserAccessRolePermission from "./../../core/hooks/useAddUserAccessRolePermission";
import useDeleteUserAccessRolePermission from "./../../core/hooks/useDeleteUserAccessRolePermission";
import useAuth from "./../../core/hooks/useAuth";

const Permissions = () => {
  const location = useLocation();
  const { id } = location.state || {};

  const { auth } = useAuth();

  const queryClient = useQueryClient();

  const addUserAccessRolePermission = useAddUserAccessRolePermission();

  const deleteUserAccessRolePermission = useDeleteUserAccessRolePermission();

  const { data: rolePermissionData, isSuccess: isRolePermissionDataSuccess } =
    useGetUserAccessRolePermissions();

  const { data: permissionData, isSuccess: isPermissionDataSuccess } =
    useGetUserAccessPermission();
  const { data: roleData, isSuccess: isRoleDataSuccess } =
    useGetUserAccessRoles();

  const { data: operationData, isSuccess: isOperationDataSuccess } =
    useGetUserAccessOperations();

  const [permissions, setPermissions] = useState([]);
  // const [roles, setRoles] = useState([]);
  const [operations, setOperations] = useState([]);
  const [rolePermissions, setRolePermissions] = useState([]);
  useEffect(() => {
    if (isPermissionDataSuccess) {
      setPermissions(permissionData?.data?.userAccessPermissions || []);
    }
    // if (isRoleDataSuccess) {
    //   setRoles(roleData?.data?.userAccessRoles || []);
    // }
    if (isOperationDataSuccess) {
      setOperations(operationData?.data?.userAccessOperations || []);
    }
    if (isRolePermissionDataSuccess) {
      setRolePermissions(rolePermissionData?.data?.userAccessRoles || []);
    }
  }, [
    isPermissionDataSuccess,
    isRoleDataSuccess,
    isOperationDataSuccess,
    isRolePermissionDataSuccess,
    permissionData,
    roleData,
    operationData,
    rolePermissionData,
    id,
    addUserAccessRolePermission.isSuccess,
    deleteUserAccessRolePermission.isSuccess,
  ]);

  // Function to check if a role has permission for an operation and permission type
  const hasPermission = (roleId, operationId, permissionId) => {
    return rolePermissions.some(
      (perm) =>
        perm.roleId == roleId &&
        perm.operations.some(
          (operation) =>
            operation.id == operationId &&
            operation.permissions.includes(permissionId)
        )
    );
  };
  const invalidateQueries = () => {
    queryClient.invalidateQueries(["userAccessRolePermissions"]);
  };
  const handleCheckboxChange = (
    isCreated,
    roleId,
    operationId,
    permissionId
  ) => {
    const payload = {
      roleId,
      operationId,
      permissionId,
      userId: auth.userId,
    };
    isCreated
      ? deleteUserAccessRolePermission.mutate(payload, {
          onSuccess: invalidateQueries,
        })
      : addUserAccessRolePermission.mutate(payload, {
          onSuccess: invalidateQueries,
        });
  };
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Permission</h4>
                <h6>Manage your permissions</h6>
              </div>
            </div>
          </div>
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                    />
                    <Link to="#" className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>

                {/* <div className="form-sort">
                  <SelectInput
                    className="img-select"
                    classNamePrefix="react-select"
                    options={roles.map((role) => ({
                      value: role.id,
                      label: role.name,
                    }))}
                    defaultKey={id}
                    onChange={(e) => e.target.value}
                  />
                </div> */}
              </div>
              <div className="table-responsive">
                <table className="table  datanew">
                  <thead>
                    <tr>
                      <th className="no-sort">
                        <label className="checkboxs">
                          <input type="checkbox" id="select-all" />
                          <span className="checkmarks" />
                        </label>
                      </th>
                      <th>Modules</th>
                      {permissions?.map((perm) => (
                        <th key={perm.id}>{perm.name}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {operations.map((op) => (
                      <tr key={op.id}>
                        <td>
                          <label className="checkboxs">
                            <input type="checkbox" />
                            <span className="checkmarks" />
                          </label>
                        </td>
                        <td>{op.name}</td>
                        {permissions.map((perm) => (
                          <td key={perm.id}>
                            <label className="checkboxs">
                              <input
                                type="checkbox"
                                checked={hasPermission(id, op.id, perm.id)}
                                onChange={() =>
                                  handleCheckboxChange(
                                    hasPermission(id, op.id, perm.id),
                                    id,
                                    op.id,
                                    perm.id
                                  )
                                }
                              />
                              <span className="checkmarks" />
                            </label>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Permissions;
