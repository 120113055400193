import { useMutation } from "@tanstack/react-query";
import getEnvVariable from "../../getEnvVariable";
import usePrivateAPIClient from "../services/usePrivateAPIClient";

const baseUrl = getEnvVariable("REACT_APP_ACCESS_MANAGER_API_BASE_URL");

const useDeleteUserAccessRole = () => {
  const { deleteById } = usePrivateAPIClient(
    baseUrl,
    "/api/v1/user-access-roles",
    true
  );
  return useMutation({
    mutationFn: ({ id, userId }) => deleteById(id, { params: { userId } }),
  });
};

export default useDeleteUserAccessRole;
