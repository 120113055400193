import React from "react";
import { useLocation } from "react-router-dom";

const UserDetail = () => {
  const location = useLocation();
  const { user } = location.state || {};

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>User Details</h4>
              <h6>Full details of user</h6>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="productdetails">
                    <ul className="product-bar">
                      <li>
                        <h4>Last Name</h4>
                        <h6>{user?.lastName}</h6>
                      </li>
                      <li>
                        <h4>First Name</h4>
                        <h6>{user?.firstName}</h6>
                      </li>
                      <li>
                        <h4>Middle Name</h4>
                        <h6>{user?.middleName}</h6>
                      </li>
                      <li>
                        <h4>Country</h4>
                        <h6>{user?.country}</h6>
                      </li>
                      <li>
                        <h4>City</h4>
                        <h6>{user?.city}</h6>
                      </li>
                      <li>
                        <h4>Phone</h4>
                        <h6>{user?.phone}</h6>
                      </li>
                      <li>
                        <h4>Address</h4>
                        <h6>{user?.address}</h6>
                      </li>
                      <li>
                        <h4>Email</h4>
                        <h6>{user?.email}</h6>
                      </li>
                      <li>
                        <h4>Hire Date</h4>
                        <h6>{user?.hireDate}</h6>
                      </li>
                      <li>
                        <h4>Monthly Salary</h4>
                        <h6>{user?.monthlySalary}</h6>
                      </li>
                      <li>
                        <h4>Username</h4>
                        <h6>{user?.username}</h6>
                      </li>
                      <li>
                        <h4>User Role</h4>
                        <h6>{user?.accessRole}</h6>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /add */}
        </div>
      </div>
    </div>
  );
};

export default UserDetail;
