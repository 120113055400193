import React from "react";

const Dashboard = () => {
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="row"></div>
      </div>
    </div>
  );
};

export default Dashboard;
