import React from "react";
import PropTypes from "prop-types";

const SelectInput = ({
  label,
  register,
  options,
  errors,
  onChange,
  defaultKey,
  ...rest
}) => {
  return (
    <>
      <label>{label}</label>
      <select
        className="form-select"
        {...register}
        onChange={onChange || (() => {})}
        {...rest}
        value={defaultKey}
      >
        {options?.map((option, key) => {
          return (
            <option key={key} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
      {errors && <p className="text-danger">{errors.message}</p>}
    </>
  );
};

SelectInput.propTypes = {
  defaultKey: PropTypes.any,
  register: PropTypes.any.isRequired,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  errors: PropTypes.string,
  onChange: PropTypes.func,
};

export default SelectInput;
