import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  ChevronUp,
  Eye,
  RotateCcw,
} from "feather-icons-react/build/IconComponents";
import { setToogleHeader } from "../../core/redux/action";
import { useDispatch, useSelector } from "react-redux";
import { PlusCircle } from "react-feather";
// import withReactContent from "sweetalert2-react-content";
// import Swal from "sweetalert2";
import Table from "../../core/pagination/datatable";
import AddUsers from "../../core/modals/usermanagement/addusers";
import EditUser from "../../core/modals/usermanagement/edituser";
import useGetUsers from "./../../core/hooks/useGetUsers";
import { all_routes } from "../../Router/all_routes";

const Users = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { data: usersData, isSuccess, isError } = useGetUsers();
  const users = usersData?.data?.users || [];

  const [currentRecord, setCurrentRecord] = useState();

  if (isError) {
    navigate("/login", { state: { from: location }, replace: true });
  }

  const transformedUsers = users?.map((user) => ({
    id: user.id,
    lastName: user.lastName,
    middleName: user.middleName,
    firstName: user.firstName,
    country: user.country,
    city: user.city,
    phone: user.phone,
    address: user.address,
    email: user.email,
    hireDate: user.hireDate,
    monthlySalary: user.monthlySalary,
    username: user.username,
    accessRole: user.accessRole,
  }));

  const dispatch = useDispatch();
  const data = useSelector((state) => state.toggle_header);

  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );

  const columns = [
    {
      title: "SN",
      dataIndex: "id",
      sorter: (a, b) => a.username.length - b.username.length,
    },

    {
      title: "Last Name",
      dataIndex: "lastName",
      sorter: (a, b) => a.phone.length - b.phone.length,
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.role.length - b.role.length,
    },
    {
      title: "Username",
      dataIndex: "username",
      sorter: (a, b) => a.role.length - b.role.length,
    },
    {
      title: "Access Role",
      dataIndex: "accessRole",
      sorter: (a, b) => a.role.length - b.role.length,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <Link
              className="me-2 p-2"
              to={all_routes.userdetails}
              state={{ user: record }}
            >
              <Eye className="feather-view" />
            </Link>
            <Link
              className="me-2 p-2"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit-units"
              onClick={() => setCurrentRecord(record)}
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            {/* <Link className="confirm-text p-2" to="#">
              <i
                data-feather="trash-2"
                className="feather-trash-2"
                // onClick={() =>
                //   showDeleteConfirmationAlert(() =>
                //     handleDeleteConfirm(record.id)
                //   )
                // }
              ></i>
            </Link> */}
          </div>
        </div>
      ),
    },
  ];
  // const MySwal = withReactContent(Swal);

  // const showDeleteConfirmationAlert = (handleClickConfirm) => {
  //   MySwal.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     showCancelButton: true,
  //     confirmButtonColor: "#00ff00",
  //     confirmButtonText: "Yes, delete it!",

  //     cancelButtonColor: "#ff0000",
  //     cancelButtonText: "Cancel",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       handleClickConfirm();
  //     } else {
  //       MySwal.close();
  //     }
  //   });
  // };

  // const handleDeleteConfirm = (id) => {
  //   deleteUser.mutate(
  //     { id, userId: auth.userId },
  //     {
  //       onSuccess: () => {
  //         MySwal.fire({
  //           title: "Deleted!",
  //           text: "The user has been deleted.",
  //           icon: "success",
  //           confirmButtonText: "OK",
  //         });
  //       },
  //       onError: () => {
  //         MySwal.fire({
  //           title: "Not Deleted!",
  //           text: "Failed to delete user.",
  //           icon: "error",
  //           confirmButtonText: "OK",
  //         });
  //       },
  //     }
  //   );
  // };
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>User List</h4>
                <h6>Manage Your Users</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={data ? "active" : ""}
                    onClick={() => {
                      dispatch(setToogleHeader(!data));
                    }}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <a
                to="#"
                className="btn btn-added"
                data-bs-toggle="modal"
                data-bs-target="#add-units"
              >
                <PlusCircle className="me-2" />
                Add New User
              </a>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                {isSuccess && (
                  <Table columns={columns} dataSource={transformedUsers} />
                )}
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
      <AddUsers />
      <EditUser currentRecord={currentRecord} />
    </div>
  );
};

export default Users;
